import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import HeadMeta from "../components/HeadMeta"
import PageHeader from "../components/PageHeader"
import Breadcrumb from "../components/Breadcrumb"
import FrontpageLookup from "../components/FrontpageLookup"
import RequestAPhrase from "../components/RequestAPhrase"
// import BrowseList from "../components/BrowseList"

import * as css from './index.module.scss'

export default ({ data }) => {
  return (
    <Layout>
      <HeadMeta />
      <PageHeader>Multilingual library signage</PageHeader>
      <Breadcrumb
        route={[{ name: "Multilingual library signage", href: "/" }]}
      />

      <p>
        The tool contains common library phrases professionally translated in{' '}
        {data.allLanguagesJson.nodes.length} languages which can be downloaded,
        ready to use in signage. 
      </p>
      <p className={css.paraEm}>
        The Multilingual Library Signage website is designed for libraries to facilitate
        communication between library staff and patrons from diverse
        linguistic backgrounds.
      </p>

      <FrontpageLookup />

      <RequestAPhrase />
    </Layout>
  )
}

export const query = graphql`
  query IndexQuery {
    allLanguagesJson(filter: { fields: { name: { ne: "" } } }) {
      nodes {
        fields {
          name
          slug
        }
      }
    }
  }
`
