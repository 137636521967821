// extracted by mini-css-extract-plugin
export var baseline = "8px";
export var borderRadius = "2px";
export var borderRadiusLg = "4px";
export var borderRadiusMd = "2px";
export var buttonUnstyled = "FrontpageLookup-module--buttonUnstyled--Dma5G";
export var colourBlack = "#111";
export var colourGrey800 = "#333";
export var colourGrey900 = "#222";
export var colourHighlight = "#ed0180";
export var colourPrimary = "#f68b1f";
export var colourSecondary = "#64c3af";
export var colourWhite = "#fff";
export var filterSpanButton = "FrontpageLookup-module--filterSpanButton--pjigb";
export var filterSpanButtonActive = "FrontpageLookup-module--filterSpanButtonActive--JgMAJ";
export var filterSpanButtons = "FrontpageLookup-module--filterSpanButtons--UFKyl";
export var fontFamilyPrimary = "\"Gotham A\",\"Gotham B\",Montserrat,Helvetica,Arial,sans-serif";
export var fontFamilySecondary = "\"Chronicle Display A\",\"Chronicle Display B\",\"Crimson Text\",\"Times New Roman\",Times,serif";
export var fontScale = "1.3";
export var formRow = "FrontpageLookup-module--formRow--2seKf";
export var listUnstyled = "FrontpageLookup-module--listUnstyled--7E8G6";
export var mediaLg = "60rem";
export var mediaMd = "40rem";
export var mediaSm = "33.5rem";
export var mediaXlg = "80rem";
export var mediaXs = "20rem";
export var mediaXxlg = "100rem";
export var phraseSelect = "FrontpageLookup-module--phraseSelect--VKXcO";
export var screenreaderOnly = "FrontpageLookup-module--screenreaderOnly--Du8em";
export var shadow = "FrontpageLookup-module--shadow--rGRUz";
export var submitButton = "FrontpageLookup-module--submitButton--tvj5G";
export var textFieldTitle = "FrontpageLookup-module--textFieldTitle--Bghkc";
export var textSmoothing = "FrontpageLookup-module--textSmoothing--I4+Lo";
export var textUppercase = "FrontpageLookup-module--textUppercase--glkfp";
export var wizardList = "FrontpageLookup-module--wizardList--yD7OW";