import * as React from "react"
import { StaticQuery, graphql, Link, navigate } from "gatsby"

import Button from "../Button"
import CTAButton from "../CTAButton"
import { Phrasify } from "../Phrase"

import * as css from "./FrontpageLookup.module.scss"
import * as utilities from "../../styles/utilities.module.scss"

interface IFilterSpan {
  from: string | null
  to: string | null
}

const FILTER_SPAN_NONE = {
  from: null,
  to: null,
}

const filterSpanDictatesActiveRange = (fspan: IFilterSpan) => {
  return fspan.from && fspan.to
}

const filterSpansEqual = (fspanA, fspanB) => {
  return fspanA.from === fspanB.from && fspanA.to === fspanB.to
}

const FrontpageLookup = ({
  formId = "frontpageLookup",
  initialLang = "en",
}) => {
  const [lang, setLang] = React.useState(initialLang)
  const [phraseFilter, setPhraseFilter] = React.useState("")
  const [phraseID, setPhraseID] = React.useState<number>(null)
  const [filterSpan, setFilterSpan] = React.useState<IFilterSpan>(
    FILTER_SPAN_NONE
  )

  const render = ({ allLanguagesJson, allPhrasesJson, allTranslationsJson }) => {
    const currentLangEdge = (allLanguagesJson?.edges || []).find(
      edge => edge?.node?.fields?.isoCode === lang
    );
    const currentLang = currentLangEdge?.node || null;

    const languageOptionsRaw = allLanguagesJson.edges;
    languageOptionsRaw.sort((a, b) => (a.node.fields.name).localeCompare(b.node.fields.name));
    const languageOptions = languageOptionsRaw.map((edge, key) => (
      <option key={key} value={edge.node.fields.isoCode}>
        {edge.node.fields.name}
      </option>
    ));

    const availablePhraseIds = allTranslationsJson.edges
      .filter((edge) => {
        return (
          edge.node.fields?.phraseID &&
          edge.node.fields?.languageID === currentLang?.fields?.id
        );
      })
      .map((edge) => edge.node.fields.phraseID);

    const phraseOptionsRaw = allPhrasesJson.edges
      .filter(edge => {
        if (!filterSpanDictatesActiveRange(filterSpan)) {
          return true
        }
        const ord = edge.node.fields.name.toLowerCase().charCodeAt(0)
        return (
          ord >= filterSpan.from.charCodeAt(0) &&
          ord <= filterSpan.to.charCodeAt(0)
        )
      })
      .filter(edge => availablePhraseIds.indexOf(edge.node.fields.id) >= 0)
      .filter(
        edge =>
          phraseFilter.length === 0 ||
          edge.node.fields.name.toLowerCase().indexOf(phraseFilter) >= 0
      );
    phraseOptionsRaw.sort((a, b) => (a.node.fields.name).localeCompare(b.node.fields.name));
    const phraseOptions = phraseOptionsRaw.map((edge, key) => (
        <option key={key} value={edge.node.fields.id}>
          {Phrasify(edge.node.fields.name, currentLang?.fields?.name)}
        </option>
      ));

    const onSubmitEvent = (e) => {
      e.preventDefault();
      const languageID = currentLang.fields.id;
      const foundPhrase = allTranslationsJson.edges.find(
        (edge) => {
          return edge.node.fields.languageID === languageID && edge.node.fields.phraseID === phraseID;
        }
      );
      if (foundPhrase) {
        navigate(`/${foundPhrase.node.fields.slug}`, {});
        return;
      }
      console.warn(
        "The phrase you selected could not route to the intended page."
      );
    }

    return (
      <form action="/" method="get" onSubmit={onSubmitEvent}>
        <div>
          <ol className={css.wizardList}>
            <li>
              <label htmlFor={`${formId}__langSelect`}>
                Select a language:
              </label>
              <select
                id={`${formId}__langSelect`}
                defaultValue={lang}
                onChange={e => setLang(e.target.value)}
              >
                {languageOptions}
              </select>
            </li>
            <li>
              <label htmlFor={`${formId}__phraseSelect`}>
                Select a phrase:
              </label>
              <label
                className={utilities.screenreaderOnly}
                htmlFor={`${formId}__phraseFilter`}
              >
                Filter list of phrases:
              </label>
              <ul className={css.filterSpanButtons}>
                <li>
                  <Button
                    type="button"
                    className={[
                      css.filterSpanButton,
                      filterSpansEqual(filterSpan, FILTER_SPAN_NONE)
                        ? css.filterSpanButtonActive
                        : "",
                    ].join(" ")}
                    onClick={() => setFilterSpan(FILTER_SPAN_NONE)}
                  >
                    All
                  </Button>
                </li>
                <li>
                  <Button
                    type="button"
                    className={[
                      css.filterSpanButton,
                      filterSpansEqual(filterSpan, { from: "a", to: "d" })
                        ? css.filterSpanButtonActive
                        : "",
                    ].join(" ")}
                    onClick={() => setFilterSpan({ from: "a", to: "d" })}
                  >
                    A-D
                  </Button>
                </li>
                <li>
                  <Button
                    type="button"
                    className={[
                      css.filterSpanButton,
                      filterSpansEqual(filterSpan, { from: "e", to: "i" })
                        ? css.filterSpanButtonActive
                        : "",
                    ].join(" ")}
                    onClick={() => setFilterSpan({ from: "e", to: "i" })}
                  >
                    E-I
                  </Button>
                </li>
                <li>
                  <Button
                    type="button"
                    className={[
                      css.filterSpanButton,
                      filterSpansEqual(filterSpan, { from: "j", to: "p" })
                        ? css.filterSpanButtonActive
                        : "",
                    ].join(" ")}
                    onClick={() => setFilterSpan({ from: "j", to: "p" })}
                  >
                    J-P
                  </Button>
                </li>
                <li>
                  <Button
                    type="button"
                    className={[
                      css.filterSpanButton,
                      filterSpansEqual(filterSpan, { from: "q", to: "s" })
                        ? css.filterSpanButtonActive
                        : "",
                    ].join(" ")}
                    onClick={() => setFilterSpan({ from: "q", to: "s" })}
                  >
                    Q-S
                  </Button>
                </li>
                <li>
                  <Button
                    type="button"
                    className={[
                      css.filterSpanButton,
                      filterSpansEqual(filterSpan, { from: "t", to: "z" })
                        ? css.filterSpanButtonActive
                        : "",
                    ].join(" ")}
                    onClick={() => setFilterSpan({ from: "t", to: "z" })}
                  >
                    T-Z
                  </Button>
                </li>
              </ul>
              <input
                type="search"
                id={`${formId}__phraseFilter`}
                value={phraseFilter}
                onChange={e => setPhraseFilter(e.target.value)}
                placeholder="Filter list of phrases eg. 'Borrow'"
              />
              <select
                className={css.phraseSelect}
                id={`${formId}__phraseSelect`}
                size={10}
                onChange={(e) => {
                  const newPhraseID = parseInt(e.target.value, 10);
                  setPhraseID(newPhraseID);
                }}
              >
                {phraseOptions}
              </select>
            </li>
            <li>
              <CTAButton
                type="submit"
                disabled={!(currentLang && phraseID)}
              >{`Translate into ${currentLang?.fields?.name}`}</CTAButton>
            </li>
          </ol>
        </div>
        <h2>
          <Link to="/language/">Browse all languages</Link>
        </h2>
        <h2>
          <Link to="/phrase/">Browse all phrases</Link>
        </h2>
      </form>
    )
  }

  return (
    <StaticQuery
      query={graphql`
        query FrontpageLookupQuery {
          allLanguagesJson {
            edges {
              node {
                fields {
                  id
                  isoCode
                  name
                }
              }
            }
          }
          allPhrasesJson {
            edges {
              node {
                fields {
                  name
                  id
                }
              }
            }
          }
          allTranslationsJson(
            filter: {
              fields: {
                enabled: { eq: true },
                languageID: { nin: [null, 0] }
                phraseID: { nin: [null, 0] }
              }
            }
          ) {
            edges {
              node {
                fields {
                  slug
                  languageID
                  phraseID
                }
              }
            }
          }
        }
      `}
      render={render}
    />
  )
}

export default FrontpageLookup
