// extracted by mini-css-extract-plugin
export var baseline = "8px";
export var borderRadius = "2px";
export var borderRadiusLg = "4px";
export var borderRadiusMd = "2px";
export var buttonUnstyled = "index-module--buttonUnstyled--pgBeJ";
export var colourBlack = "#111";
export var colourGrey800 = "#333";
export var colourGrey900 = "#222";
export var colourHighlight = "#ed0180";
export var colourPrimary = "#f68b1f";
export var colourSecondary = "#64c3af";
export var colourWhite = "#fff";
export var fontFamilyPrimary = "\"Gotham A\",\"Gotham B\",Montserrat,Helvetica,Arial,sans-serif";
export var fontFamilySecondary = "\"Chronicle Display A\",\"Chronicle Display B\",\"Crimson Text\",\"Times New Roman\",Times,serif";
export var fontScale = "1.3";
export var formRow = "index-module--formRow--dMTpn";
export var listUnstyled = "index-module--listUnstyled--mrREi";
export var mediaLg = "60rem";
export var mediaMd = "40rem";
export var mediaSm = "33.5rem";
export var mediaXlg = "80rem";
export var mediaXs = "20rem";
export var mediaXxlg = "100rem";
export var paraEm = "index-module--paraEm--WB2+o";
export var screenreaderOnly = "index-module--screenreaderOnly--gmYGC";
export var shadow = "index-module--shadow--t68yG";
export var submitButton = "index-module--submitButton--gPcn+";
export var textFieldTitle = "index-module--textFieldTitle--OtK0I";
export var textSmoothing = "index-module--textSmoothing--+5Qbu";
export var textUppercase = "index-module--textUppercase--en6bh";