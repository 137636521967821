// extracted by mini-css-extract-plugin
export var baseline = "8px";
export var borderRadius = "2px";
export var borderRadiusLg = "4px";
export var borderRadiusMd = "2px";
export var buttonUnstyled = "CTAButton-module--buttonUnstyled--5PVGS";
export var colourBlack = "#111";
export var colourGrey800 = "#333";
export var colourGrey900 = "#222";
export var colourHighlight = "#ed0180";
export var colourPrimary = "#f68b1f";
export var colourSecondary = "#64c3af";
export var colourWhite = "#fff";
export var ctaButton = "CTAButton-module--ctaButton--aL1q0";
export var dark = "CTAButton-module--dark--bSGCB";
export var fontFamilyPrimary = "\"Gotham A\",\"Gotham B\",Montserrat,Helvetica,Arial,sans-serif";
export var fontFamilySecondary = "\"Chronicle Display A\",\"Chronicle Display B\",\"Crimson Text\",\"Times New Roman\",Times,serif";
export var fontScale = "1.3";
export var formRow = "CTAButton-module--formRow--BRAiu";
export var grey = "CTAButton-module--grey--w3cGs";
export var iconHasChildren = "CTAButton-module--iconHasChildren--nwcXy";
export var light = "CTAButton-module--light---jlF7";
export var listUnstyled = "CTAButton-module--listUnstyled--02xtd";
export var mediaLg = "60rem";
export var mediaMd = "40rem";
export var mediaSm = "33.5rem";
export var mediaXlg = "80rem";
export var mediaXs = "20rem";
export var mediaXxlg = "100rem";
export var outlineDark = "CTAButton-module--outlineDark--rODqQ";
export var screenreaderOnly = "CTAButton-module--screenreaderOnly--Uvt05";
export var shadow = "CTAButton-module--shadow--z6qpT";
export var sm = "CTAButton-module--sm--FDhmL";
export var submitButton = "CTAButton-module--submitButton--ca1Yv";
export var textFieldTitle = "CTAButton-module--textFieldTitle--Txhtt";
export var textSmoothing = "CTAButton-module--textSmoothing--jam8l";
export var textUppercase = "CTAButton-module--textUppercase--r1HAP";