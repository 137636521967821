import * as React from "react"

import Button from "../Button"
import SVGIcon, { SVGIconName } from "../SVGIcon"

import * as css from "./CTAButton.module.scss"

/* eslint css-modules/no-unused-class: [2, { markAsUsed: ['sm', 'light', 'grey', 'outlineDark'] }] */

type props = {
  iconName?: SVGIconName
  size?: "sm" | "md"
  iconSize?: "xs" | "sm" | "md" | "lg"
  variant?: "light" | "dark" | "grey" | "outlineDark"
  type?: "button" | "submit" | "reset"
  disabled?: boolean
  children: string | React.ReactNode
  as?: string
  href?: string
  className?: string
  iconClassName?: string
}

// React.forwardRef added because of:
// Warning: Function components cannot be given refs. Attempts to access
// this ref will fail. Did you mean to use React.forwardRef()? Check the
// render method of `Link`.
// https://github.com/zeit/next.js/issues/7915
const CTAButton: React.FunctionComponent<props> = React.forwardRef(
  (
    {
      iconName,
      size = "md",
      iconSize = "md",
      variant = "dark",
      disabled = false,
      type,
      children,
      as,
      href,
      className,
      iconClassName,
      ...restProps
    },
    ref
  ) => {
    const Component = as || Button

    const hasChildren = Boolean(children)

    return (
      <Component
        type={Component === Button ? type : undefined}
        disabled={disabled}
        className={[
          css.ctaButton,
          size !== "md" ? css[size] : "",
          iconSize,
          css[variant],
          className || "",
        ].join(" ")}
        {...restProps}
        href={href}
        ref={ref}
      >
        {children}
        {iconName ? (
          <SVGIcon
            name={iconName}
            size={iconSize}
            variant={variant === "dark" ? "white" : "black"}
            className={[
              hasChildren ? css.iconHasChildren : "",
              iconClassName || "",
            ].join(" ")}
          />
        ) : null}
      </Component>
    )
  }
)

export default CTAButton
